import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Navbar.css";
import mainman from "../Assets/images/mainman.svg";

const Navbar = () => {
  const navLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About Me",
      path: "/about",
    },
    {
      title: "LinkedIn",
      path: "https://www.linkedin.com/in/raymondmbam",
    },
    {
      title: "Resume",
      path: "https://drive.google.com/file/d/1-unPscElYAkIlGBTke5uxqRixlYQl2Ue/view",
    },
  ];
  const [showNav, setShowNav] = useState(false);
  const toogleMenu = () => {};
  return (
    <nav className="navi_gate">
      <div className="desk_nav">
        <div className="Logo-nav">
          <div className="img-navs">
            <img src={mainman} alt="mainman" />

            <div className="hamburger" onClick={() => setShowNav(!showNav)}>
              <span className={showNav ? "cancel1 bars" : "bars"}></span>
              <span className={showNav ? "cancel2 bars" : "bars"}></span>
              <span className={showNav ? "cancel3 bars" : "bars"}></span>
            </div>
          </div>

          <div className="nav-link1">
            <Link className="navLink" to="/">
              Home
            </Link>
            <Link className="navLink" to="/about">
              About Me
            </Link>
          </div>
        </div>

        <div className="nav-link2">
          <Link to="https://www.linkedin.com/in/raymondmbam" className="navLink">
            LinkedIn
          </Link>
          <Link to="https://drive.google.com/file/d/1-unPscElYAkIlGBTke5uxqRixlYQl2Ue/view" className="navLink">
            Resume
          </Link>
        </div>
      </div>
      <div className={showNav ? "secondNav" : "hid"}>
        {navLinks.map((item, index) => (
          <Link to={item.path} key={index}>
            {item.title}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
